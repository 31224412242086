exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gatsby-sample-tsx": () => import("./../../../src/pages/gatsbySample.tsx" /* webpackChunkName: "component---src-pages-gatsby-sample-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-popups-attendance-popup-tsx": () => import("./../../../src/pages/popups/AttendancePopup.tsx" /* webpackChunkName: "component---src-pages-popups-attendance-popup-tsx" */),
  "component---src-pages-popups-bill-popup-tsx": () => import("./../../../src/pages/popups/BillPopup.tsx" /* webpackChunkName: "component---src-pages-popups-bill-popup-tsx" */),
  "component---src-pages-popups-chat-popup-tsx": () => import("./../../../src/pages/popups/ChatPopup.tsx" /* webpackChunkName: "component---src-pages-popups-chat-popup-tsx" */),
  "component---src-pages-popups-communication-popup-tsx": () => import("./../../../src/pages/popups/CommunicationPopup.tsx" /* webpackChunkName: "component---src-pages-popups-communication-popup-tsx" */),
  "component---src-pages-popups-homework-popup-tsx": () => import("./../../../src/pages/popups/HomeworkPopup.tsx" /* webpackChunkName: "component---src-pages-popups-homework-popup-tsx" */),
  "component---src-pages-popups-sample-tsx": () => import("./../../../src/pages/popups/sample.tsx" /* webpackChunkName: "component---src-pages-popups-sample-tsx" */),
  "component---src-pages-popups-small-feature-tsx": () => import("./../../../src/pages/popups/SmallFeature.tsx" /* webpackChunkName: "component---src-pages-popups-small-feature-tsx" */),
  "component---src-pages-sub-api-tsx": () => import("./../../../src/pages/sub/api.tsx" /* webpackChunkName: "component---src-pages-sub-api-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

